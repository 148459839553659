import NavigationComponent from '../component/forms/navigation';
import PropTypes from 'prop-types';
import React from 'react';
import SEO from '../component/seo';

const IndexPage = ({ location }) => {
  return (
    <>
      <SEO title='Get Rates Now' />
      <NavigationComponent
        location={location}
        isMedicareAdvantage={false}
        isPDP={false}
        isMS={true}
      />
    </>
  );
};

IndexPage.propTypes = {
  location: PropTypes.object,
};

export default IndexPage;
